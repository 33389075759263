@font-face {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/l/font?kit=aFTR7PB1QTsUX8KYvrGyIYS_Yp3NbGt7jJxJXIA&skey=d4f206f6aecab2b9&v=v14) format('truetype');
}
@font-face {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/l/font?kit=aFTU7PB1QTsUX8KYhh2CC7S8Wp7Ia254gZM&skey=7fffa551dba78cac&v=v14) format('truetype');
}
@font-face {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/l/font?kit=aFTR7PB1QTsUX8KYvumzIYS_Yp3NbGt7jJxJXIA&skey=e96fea085afb5a8a&v=v14) format('truetype');
}
